import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.section`
  position: relative;
  box-sizing: border-box;
  background-color: ${props => props.bgColor || '#fff'};
  min-height: 40vh;

  .box {
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    h3 {
      font-family: Lora;
      font-size: 34px;
      color: #75b589;
      letter-spacing: -0.09px;
    }
  }
`;

export const BlogReelContainer = styled.div`
  margin-left: 1.8rem;

  ${MEDIA.PHONE`
    margin-left: 0;
  `};
`;
