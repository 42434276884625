import React from 'react';
import PropTypes from 'prop-types';
import Box from 'components/box';
import Gallery from 'components/gallery';
import Title from 'components/title';
import { Container } from './blogReel.css';

const BlogReel = ({ title = 'Recent News', content = null, posts = {} }) => (
  <Container id="blogReel">
    <Box>
      {title && <Title as="h3">{title}</Title>}
      {content ? <p>{content}</p> : null}

      <Gallery
        items={posts.map(({ title, slug, date, excerpt, featured_media }) => {
          return {
            title: date,
            link: slug,
            name: title,
            copy: excerpt,
            image: featured_media,
          };
        })}
      />
    </Box>
  </Container>
);

BlogReel.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  content: PropTypes.string,
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BlogReel;
