import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Head from 'components/head';
import Layout from 'components/layout';
import BlogReel from 'components/sections/blogReel';
import { BlogReelContainer } from 'components/sections/blogReel/blogReel.css';

import Section from '~v2/section';
import Wrapper from '~v2/elements/container/wrapper';
import Title from '~v2/elements/title';
import { TitleContainer } from '~v2/elements/title/title.css';

const Careers = ({
  data: {
    careersJson: { title, header, left_rail_bg },
    allMarkdownRemark: { edges: jobs },
  },
}) => {
  const blogItems = jobs.map(({ node: job }) => {
    return {
      title: job.frontmatter.title,
      slug: `${job.fields.slug}`,
      excerpt: job.frontmatter.description,
    };
  });

  return (
    <Layout isSmallHeader={true}>
      <Head
        pageTitle={title}
        pageDescription="As the hemp industry grows, so too does Trace and the demand for our software. Learn about Trace careers today to get involved."
      />
      <Wrapper>
        <TitleContainer>
          <Title as="h1">{header.title}</Title>
        </TitleContainer>
        <Section
          boundsProps={{
            fluid: true,
            style: { width: '100%' },
            DESKTOP_DOWN: { padding: '0 2rem' },
            DESKTOP: { padding: '0 2rem' },
          }}
          left={{
            boxProps: {
              fluid: true,
              TABLET_DOWN: { width: '0%' },
              TABLET: { width: '5%' },
              DESKTOP_UP: { width: '15%' },
            },
            backgroundProps: {
              image: left_rail_bg,
            },
          }}
          right={{
            children: (
              <BlogReelContainer>
                <BlogReel title={false} posts={blogItems} />
              </BlogReelContainer>
            ),
            boxProps: {
              fluid: true,
              PHONE: { padding: '0' },
              TABLET_DOWN: { width: '100%', padding: '0 2rem' },
              TABLET: { width: '95%' },
              DESKTOP_UP: { width: '85%' },
            },
          }}
        />
      </Wrapper>
    </Layout>
  );
};

Careers.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Careers;

export const query = graphql`
  query CareersQuery {
    careersJson {
      title
      header {
        title
      }
      left_rail_bg {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(careers)/" } }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featured_image {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 560, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
